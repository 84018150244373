
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { apiCategoryDel, apiCategoryLists, apiCategoryStatus, apiPlatformGetMainGoods } from '@/api/goods'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    $refs!: { plTreeTable: any }
    pager = new RequestPaging()

    getList(): void {
        this.pager
            .request({
                callback: apiCategoryLists,
                params: { pager_type: 1,is_show_main: 1 }
            })
            .then(res => {})
    }
    handleStatus(value: number, id: number) {
        apiCategoryStatus({
            id,
            is_show: value
        }).then(() => {
            this.getList()
        })
    }
	
	getMainGoods(){
		apiPlatformGetMainGoods({}).then(res=>{
			console.log( res )
		})
	}
	test(){
		apiPlatformGetMainGoods({}).then(res=>{
			console.log( res )
		})
	}

    handleDelete(id: number) {
        apiCategoryDel(id).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
    activated() {
        this.getList()
    }
}
